import Honeybadger from "@honeybadger-io/js"

const apiKey = document.querySelector('meta[name="VITE_HONEYBADGER_API_KEY"]')?.content
const revisionNumber = document.querySelector('meta[name="VITE_HONEYBADGER_REVISION"]')?.content

if(apiKey) {
  const honeybadgerClient = Honeybadger.configure({
    apiKey,
    environment: import.meta.env.PROD ? 'production' : 'development',
    revision: revisionNumber,
    maxErrors: 20,
  })

  // Ensure it is always globally accessible
  if (!window.Honeybadger) {
    Object.defineProperty(window, "Honeybadger", {
      value: honeybadgerClient,
      writable: false, // Prevent accidental overwrites
      configurable: false, // Prevent deletion
    });
  }

  // Ignore Third-Party Script Errors
  const ignoredDomains = [
    "plausible.io",
    "client.crisp.chat",
    "js.stripe.com",
  ];

  Honeybadger.beforeNotify((notice) => {
    if(ignoredDomains.some(domain => notice.stack.includes(domain))) {
      return false
    }
    return true
  })
}

document.querySelectorAll('.js-boomtown').forEach(element => {
  element.addEventListener('click', e => {
    e.preventDefault()
    setTimeout(() => { throw new Error('boomtown') }, 0)
    alert('A JavaScript error is being thrown.')
  }, false)
})
